import gql from 'graphql-tag';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { CognitoUser } from 'models/User';

type PolicyRules = {
  roles: string[];
  access?: string;
  nestedRoutes?: { [key: string]: PolicyRules };
};

type Policies = { [key: string]: PolicyRules };

export interface AuthorizerResponse {
  roles: string[];
  menuPolicies: Policies;
  policiesLoading: boolean;
  getAnAuthorizedPage: () => string;
  isAllowed: (policy: string, policies: Policies) => void;
}

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    profile: getProfile {
      email
      id
      name
      sub
      role
      resource_policies
      permissions {
        university {
          id
          code
        }
      }
      permissions_pav1 {
        university_code
        university_id
        goal_group_code
      }
    }
  }
`;

const programRoles = ['ProgramViewer'];
const EnrollmentRoles = ['EnrollmentUser', 'EnrollmentAdmin'];
const StudentSupportRoles = ['StudentSupport', 'StudentSupportAdmin'];
const MarketingRoles = ['MarketingUser', 'MarketingAdmin'];
const AdminRoles = ['SuperAdmin', 'Executive', 'SupportAdmin', 'SolutionsAdmin'];
// Operations Partner commented because the permissions they have, leave it just for reference
const OperationsRoles = ['OperationsUser', 'OperationsAdmin' /* 'OperationsPartner' */];
const TermsAdmin = ['SuperAdmin', 'SolutionsAdmin', 'OperationsAdmin', 'StudentSupportAdmin'];
const DevRoles = ['AgentAdmin']

const noodleRoles = [
  ...AdminRoles,
  ...OperationsRoles,
  ...EnrollmentRoles,
  ...StudentSupportRoles,
  ...MarketingRoles,
  ...DevRoles,
];

const PartnerRolesMap: { [key: string]: string } = {
  enrollment: 'EnrollmentPartnerUser',
  student_support: 'StudentSupportPartnerUser',
  marketing: 'MarketingPartnerUser',
  operations: 'OperationsPartner',
  enrollmentRestricted: 'EnrollmentPartnerRestricted',
  operationsRestricted: 'OperationsPartnerUserRestricted',
  marketingRestricted: 'MarketingPartnerUserRestricted',
};

const PagesPolicies: Policies = {
  '/enrollment': {
    roles: [
      ...noodleRoles,
      PartnerRolesMap['enrollment'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['enrollmentRestricted'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  '/marketing': {
    roles: [
      ...noodleRoles,
      PartnerRolesMap['marketing'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['marketingRestricted'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  '/student-support': {
    roles: [
      ...noodleRoles,
      PartnerRolesMap['student_support'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  '/year-over-year': {
    roles: [...noodleRoles],
    access: '*',
  },
  '/management': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/management/partners-and-programs': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/management/terms': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/management/goals': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/management/keydates': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/reports': {
    roles: noodleRoles,
    access: '*',
  },
  '/management/partners-and-programs/partner-intakes': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  '/admin/users': {
    roles: AdminRoles,
    access: '*',
  },
  '/development/agents': {
    roles: DevRoles,
    access: '*',
  },
  '/wiki': {
    roles: [
      ...noodleRoles,
      'NoodleWikiUser',
    ],
    access: '*',
  },
};

const CustomPolicies: Policies = {
  home: {
    roles: noodleRoles,
    access: '*',
  },
  integrations: {
    roles: noodleRoles,
    access: '*',
  },
  'menu/home': {
    roles: noodleRoles,
    access: '*',
  },
  'menu/enrollment': {
    roles: [
      ...noodleRoles,
      PartnerRolesMap['enrollment'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['enrollmentRestricted'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  'menu/marketing': {
    roles: [
      ...AdminRoles,
      ...MarketingRoles,
      ...OperationsRoles,
      PartnerRolesMap['marketing'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['marketingRestricted'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  'menu/student_support': {
    roles: [
      ...AdminRoles,
      ...StudentSupportRoles,
      ...OperationsRoles,
      PartnerRolesMap['student_support'],
      PartnerRolesMap['operations'],
      PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  'menu/year_over_year': {
    roles: [
      ...noodleRoles,
      // PartnerRolesMap['operations'],
      // PartnerRolesMap['operationsRestricted'],
    ],
    access: '*',
  },
  'menu/management': {
    roles: [...noodleRoles, ...programRoles],
    access: '*',
  },
  'menu/reports': {
    roles: AdminRoles, // noodleRoles,
    access: '*',
  },
  'menu/admin': {
    roles: AdminRoles,
    access: '*',
  },
  'management/terms/edit': {
    roles: TermsAdmin,
    access: '*',
  },
  'menu/development': {
    roles: DevRoles,
    access: '*',
  },
  'menu/wiki': {
    roles: [
      ...noodleRoles,
      'NoodleWikiUser',
    ],
    access: '*',
  },
  ...PagesPolicies,
};

function parseRoles(roles: string = ''): string[] {
  const separateByCommas = roles.split(',');
  const sanitizeRoles = separateByCommas.map((role: string) =>
    role.replace('[', '').replace(']', '').trim(),
  );
  return sanitizeRoles;
}

function useAuthorizer<T>() {
  const [roles, setRoles] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<number[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { data, error } = useQuery(GET_USER_PROFILE, {
    onCompleted: (data: { profile: CognitoUser }) => {
      setRoles(parseRoles(data?.profile?.role));
      setPermissions(
        data?.profile?.permissions_pav1?.map(
          (permission: { university_id: number }) => permission.university_id,
        ),
      );
      setLoading(false);
    },
  });

  const isAllowed = (policy: string) => {
    const policyRoles: string[] = CustomPolicies[policy]?.roles ?? [];
    for (let i = 0; i < roles.length; i++) {
      const userRole: string = roles[i];
      if (isRoleInPolicy(policyRoles, [userRole])) return true;
    }
    return false;
  };

  const getAnAuthorizedPage = (): string => {
    const pagesList = Object.entries(PagesPolicies);

    for (let i = 0; i < pagesList.length; i++) {
      for (let j = 0; j < roles.length; j++) {
        const userRole: string = roles[j];
        if (isRoleInPolicy(pagesList[i][1].roles, [userRole])) return pagesList[i][0];
      }
    }

    return '/404';
  };

  const isRoleInPolicy = (arr: string[], values: string[]) => {
    return values.every((value) => {
      return arr.includes(value);
    });
  };

  return {
    isAllowed,
    roles: roles,
    userData: data,
    userError: error,
    getAnAuthorizedPage,
    permissions: permissions,
    policiesLoading: loading,
  };
}

export function isExperimentalUser(roles: string[]): boolean {
  return roles.includes('ExperimentalFeaturesUser') ||
         roles.includes('ExperimentalFeaturesPartnerUser') ||
         roles.includes('NoodleWikiUser');
}

export function isNoodleWikiUser(roles: string[]): boolean {
  return roles.includes('NoodleWikiUser');
}

export function isSingleRole(role: string, roles?: string[]): boolean {
  return roles?.length === 1 && roles[0] === role;
}

export default useAuthorizer;
